/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
//import Link from '@mui/material/Link';
import '../../PayrollSystemAccess.css';
//import AES from 'crypto-js/aes';
import Crypto from 'crypto-js';
//import { db } from '../../../../components/firebase';
//import { doc, setDoc } from "firebase/firestore"; 
import Customers from '../../../../components/airtable';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

const phoneRegExp = /(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/

const validationSchema = yup.object().shape({
  payrollSystem: yup
    .string()
    .trim()
    .required('The name of your payroll system is required'),
  email: yup
    .string()
    .trim()
    .email('The email address is invalid')
    .required('Email address of the account owner is required')
  ,
  mobile: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid. Please use the format 111-111-1111')
    .required('The mobile phone of the account holder is required'),
  login: yup
    .string()
    .trim()
    .required('The login id/username for your payroll system is required'),
  password: yup
    .string()
    .trim()
    .required("The password for your payroll system is required")
});

const Form = () => {
  const [id, setId] = useQueryParam("id", StringParam);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const initialValues = {
    email: '',
    payrollSystem: '',
    login: '',
    password: '',
    mobile: ''
  };

  const onSubmit = async (values) => {
    var cypherText = Crypto.AES.encrypt(JSON.stringify(values), "Ragaasavari2o21!!!").toString();

    Customers('Questionaire').update([
      {
        "id": id,
        "fields": {
          "Payroll System Credentials": cypherText
        }
      }
    ], function (err, records) {
      if (err) {
        setError(true);
        return;
      }

      records.forEach(function (record) {
        setSuccess(true);
      })
    });
    // Temp write to airtable

    // Fix for firebase storage over the weekend.
    /*try {
      const docRef = await setDoc(doc(db, "payroll", 'test'), values);
      console.log(docRef);
    }
    catch (e) {
      console.log(e);
    }
    finally {
      console.log("In finally")
    }*/


  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>

      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Payroll System
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
          className="textSecondaryPadding"
        >
          Payroll System Information
        </Typography>
        {!success && <Typography component="div" color="text.secondary">
          <b>IMPORTANT: </b>By filling out this form, you are granting us access to your payroll system. This
          access is limited to running reports for the express purpose of computing your
          Employee Retention Credit (ERTC). All payroll information is confidential, and will <b>NOT</b> be used for any
          other purpose other than to compute the ERTC credit for your organization.<br /><br />

          A few things you should know when filling out this form:


          <ol>
            <li>The data you provide here is encrypted using a strong cryptographic algorithm</li>
            <li>The data is stored for only as long as we require access to your payroll system</li>
            <li>Once we have run the required reports, this data is automatically destroyed. When
              it is destroyed, you will be notified by email.
            </li>
            <li><b>IMPORTANT:</b> Some payroll systems require 2 factor authentication. This means that upon login
              a code is sent to an email address or mobile phone number. When we attempt to login, this code
              will be sent to the owner of the account.
            </li>
            <li>
              We will then contact the owner of the account to
              get that code in order for us to complete the login process.
            </li>
            <li>
              <b>Please make sure that the email and phone number you provide belongs to the account owner
                where the verification code is delivered.</b>
            </li>
          </ol>

        </Typography>
        }
      </Box>

      {error &&
        <Box>
          <Alert severity="error">
            <AlertTitle>Error Saving Your Credentials</AlertTitle>
            We are sorry, but for some reason there was an error saving your credentials to our
            database. Please call Kash at 571-220-6208.
          </Alert>
        </Box>
      }

      {success &&
        <Box>
          <Alert severity="success">
            <AlertTitle>Your encrypted credentials have been saved!</AlertTitle>
            <Typography variant="h4">In the next 48 hours we will provide you with the total amount of your credit!</Typography>
            <p>If you have any questions please call us at 571-220-6208 or send an email to hi@ert.credit</p>
          </Alert>
        </Box>
      }
      {!success && <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
              What payroll system do you use? (ADP, Paychex etc)
            </Typography>
            <TextField
              label="* Payroll System"
              variant="outlined"
              name={'payrollSystem'}
              fullWidth
              value={formik.values.payrollSystem}
              onChange={formik.handleChange}
              error={formik.touched.payrollSystem && Boolean(formik.errors.payrollSystem)}
              helperText={formik.touched.payrollSystem && formik.errors.payrollSystem}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
              What is the username for your payroll system?
            </Typography>
            <TextField
              label="* Payroll System username"
              variant="outlined"
              name={'login'}
              fullWidth
              value={formik.values.login}
              onChange={formik.handleChange}
              error={formik.touched.login && Boolean(formik.errors.login)}
              helperText={formik.touched.login && formik.errors.login}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
              What is the password for your payroll system?
            </Typography>
            <TextField
              label="* Payroll System password"
              variant="outlined"
              name={'password'}
              fullWidth
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
              What is the email address for this account owner?
            </Typography>
            <TextField
              label="Account Owner Email"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle1'} sx={{ marginBottom: 2 }}>
              What is the mobile number for this account owner?
            </Typography>
            <TextField
              label="Account Owner Mobile Phone"
              variant="outlined"
              name={'mobile'}
              fullWidth
              value={formik.values.mobile}
              onChange={formik.handleChange}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
              helperText={formik.touched.mobile && formik.errors.mobile}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button size={'large'} variant={'contained'} type={'submit'}>
                  Save Payroll Access Data
                </Button>
              </Box>

            </Box>
          </Grid>
        </Grid>
      </form>
      }
    </Box>
  );
};

export default Form;
